// bracketed lists

%bracket {
    & > li {
        &:after {
            content: '|';
            margin-left: 1em;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}
