/*
Styles for category page
*/
/* 
    Created on : May 9, 2016, 8:45:39 AM
    Author     : Sky Tonnessen
*/

.category-container .category-description {
    h1 {
        margin: 0;
        margin-bottom: .5em;
        color: #636363;
        font-family: "Roboto Condensed","Helvetica Neue",Verdana,Arial,sans-serif;
        font-size: 24px!important;
        font-weight: 300;
        font-style: normal;
        line-height: 1.2;
        text-rendering: optimizeLegibility;
        text-transform: uppercase;
    }
    
    h2 {
        margin: 0;
        margin-bottom: .7em;
        color: #1e3d76;
        font-family: "Roboto Condensed","Helvetica Neue",Verdana,Arial,sans-serif;
        font-size: 2.5em;
        font-weight: 300;
        font-style: normal;
        line-height: 1.2;
        text-rendering: auto;
        text-transform: uppercase;
    }
}