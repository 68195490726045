.cta-banner-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 3;
}
.cta-box{
    position: relative;
    z-index: 0;
    text-align: center;
    color: $c-text-white;
    cursor: pointer;
    position: relative;
    background-size: cover;
    display: flex;
    width: 100%;
    line-height: 1;
    padding: 3em 2em 5em;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 1.25em;
    span {
        position: relative;
        z-index: 2;
        font-size: 2.25em;
        text-transform: uppercase;
    }
    .cta-action {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 2em;
            font-size: 1.25em;
            display: block;
            margin-top: 2em;
            font-weight: 400;
            &:after {
                font-family: 'vecoplan';
                text-transform: none;
                content: "c";
                color: $c-ltblue;
                position: relative;
                top: 1px;
            }
    }
    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgb(66, 66, 66); /* Fall-back for browsers that don't support rgba */
        background: rgba(66, 66, 66, .5);
    }
    &:first-child {
        &:before {
            /*background: rgb(0, 60, 110); /* Fall-back for browsers that don't support rgba */
            /*background: rgba(11, 101, 190, .6); */           
        }
    }
    &:nth-child(2) {
        &:before {
            background: rgb(33, 33, 33); /* Fall-back for browsers that don't support rgba */
            background: rgba(33, 33, 33, .5);
        }
    }
    &:hover,
    &:active,
    &:focus {
        &:before {
            background: rgb(30, 144, 255); /* Fall-back for browsers that don't support rgba */
            background: rgba(30, 144, 255, .5);
            background-size: cover;
        }
        color: $c-text-white;
        text-decoration: none;
    }
    color: $c-text-white;
    display: block;
}

@media only screen and (min-width: $fullWidth) {
    .cta-banner-wrapper {
        flex-direction: row;
        margin: -15px -15px 0;
    }
    .cta-box {
        width: 33.3333%;
        padding: 4em 2em 7em;
        .cta-action {
            bottom: 3.5em;
        }
    }
}
