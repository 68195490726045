.catalog-product-view .main-container{
	width: 100%;
	padding: 0;
}
.product-view{
	h3 {
		color: lightblue;
	}
	.box-collateral h2 {
		text-align: center;
		color: lightblue;
	}
}
.product-overview, .product-features, .product-brochures{
    color: $c-text-white;
    padding: 20px 0;
    clear: both;
    h2 {
        color: lightblue !important;
        text-align: right;
    }
    a {
        color: $c-text-white;
    }
}
.product-secondary {
    margin-bottom: 2em;
    &:after,
    &:before,
    & {        
        background: linear-gradient(rgba(13, 92, 237, 0.4), rgba(13, 92, 237, 0.4)), url("../images/factory-bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.product-overview {
    &:after,
    &:before,
    & {        
        background-color: $c-blue;
    }
}
.product-essential {
    padding: 1em;
}
@media only screen and (min-width: 768px) {
    .product-essential {
        padding: 1em 0 1em 8em;
    }
    .product-view {
        padding: 3.25em 1em 1em;
    }
    .product-secondary,
    .product-overview {
        padding: 2em 50em 2em 58em;
        margin: 0 -50em;
    }
    .product-secondary {
        margin-bottom: 2em;
    }
}

/*Fix*/
.col1-layout{
    .product-overview h2, .product-features h2, .product-brochures h2 {
        color: lightblue;
    }
    .product-features ul {
        list-style: disc outside;
        li{
            color: $c-text-white;
        }
    }
    .more-views__viewbox {
        overflow: hidden;
    }
    .product-image-thumbs {
        margin: 0;
        li {
            margin: 0;
        }
    }
}
@media only screen and (min-width: 768px) {
    .col1-layout{
        .product-features ul{
            column-count: 2;
        }
    }
}