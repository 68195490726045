$sliderHeight: 800px;
$fullWidth: 1260px;

.hero-wrapper {
    position: relative;
    margin: -30px -15px 0;
    min-height: $sliderHeight / 2;
    &:hover {
        .hero__icon {
            &.prev {
                left: 2em;
            }
            &.next {
                right: 2em;
            }
        }   
    }
}
.hero {
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    text-align: center;
    height: $sliderHeight / 2;
    opacity: 0;
    transition: opacity 1s ease-in;
    z-index: 0;
    a {
        color: #fff;
        color: #eee;
        &:hover {
            text-decoration: none;
        }
    }
    &.current {
        z-index: 1;
        opacity: 1;
    }
    img {
        display: none;
    }
}
.hero__image {
    display: none;
}

.hero__icon {
    color: $c-blue;
    font-family: 'vecoplan';
    position: absolute;
    font-size: 2.5em;
    top: 50%;
    z-index: 9999;
    cursor: pointer;
    transition: translate .1s ease-in;
    &.prev {
        transition: left .24s ease-in;
        left: 1em;
    }
    &.next {
        transition: right .24s ease-in;
        right: 1em;
    }
    &:after {
        content: '';
        position: absolute;
        top: .4em;
        left: .1em;
        width: .75em;
        height: .75em;
        display: block;
        background-color: $c-ltblue;
        border-radius: 50%;
        z-index: -1;
    }
    &:active {
        transform: translate(1px,1px);
    }
}

.hero__text {
    display: inline-block;
    margin: 2.5em auto;
    padding: 3em 4em;
    color: $c-text-white;
    text-align: center;
    // background-color: $c-ltblue;
    background-color: rgba(120, 188, 224, .9);
    border:0 none;
    left: 50%;
    font-size: 1.25em;
}
.hero__title {
    line-height: 1.4;
    margin-bottom: 0;
    font-weight: 100;
    color: #ffffff;
    letter-spacing: 2px;
    font-size: 2.75em;
}

.hero {
    margin-bottom: 0;
    .slides li {
        height: $sliderHeight / 2;
        text-align: center;
        img {
            max-width: initial;
            position: absolute;
            top:0;
            bottom: 0;
        }
        @media only screen and (max-width: 768px) {
            img {
                height: 100% !important;
                width: initial !important;
            }
        }
    }
} 


.cws-arw a {
    // top: 250px;
    &:before {
        color: #ffffff;
    }
}
@media only screen and (min-width: $fullWidth) {
    .hero-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: $sliderHeight;
    }
    .hero {
        height: $sliderHeight;
    }
    .hero__text {
        position: absolute;
        top: 35%;
        left: 50%;
        width: 500px;
        margin-left: -250px;
        font-size: 1.5em;
        padding: .75em 3em 1em;
    }
    .hero__title {
        font-size: 5em;
        line-height: 1;
    }
    .cms-home {        
        .col-main {
            padding-top: $sliderHeight - 450px;
        }
    }
}