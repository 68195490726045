.clcopyright {
    display: none;
}

.news-item {
    font-size: 1.2em;
    padding: 1em .5em;
    margin-bottom: 1em;
    &-content {
        padding-top: 2em;
        font-size: 1.4em;
    }
    &:nth-of-type(2n + 1) {
        background-color: lighten($c-ltblue, 31%);
    }
}
.news-container {
    padding: 1em 7.75em;
   
    .date {
        display: inline-block;
        margin-bottom: 1em;
        font-size: 14px;
    }
    line-height: 1.4;
    h2 {
        font-size: 3em;
    }
    h4 {
        font-size: 1.5em;
        color: $c-blue;
    }
    h5 {
        font-size: 1.25em;
    }
    .news {
        margin-bottom: 1.5em;
    }
}
.news-container + .news-container {
    padding-top: 0;
    margin-top: -2em;
    clear: both;
    padding-top: 20px;
}
.news-categories {
    padding-top: 2em;
    .block-title {         
        margin-bottom: 0;
        padding-bottom: 1.2em;
        line-height: 1;
        strong {
            font-size: 1.25em;
        }
    } 
    .block-title,
    .block-content {
        padding-left: 14px; 
        border-left: 1px solid #ccc;
    }
    .block-content {
        margin-top: 0;
        padding-bottom: 3.25em;
    }
}
.author,
.news-author {
    font-weight: bold;
}
.news-author {
    font-size: 14px;
}

.news-image,
.news_image {
    padding-bottom: 1em;
}
.news_image {
    float: left;
    margin: 0 1em 1em 0;
}
.news-item-content {
    .news_image {
        float: none;
    }
    ul {
            list-style-type: disc;
            margin-left: 1.5em;
  }
}
