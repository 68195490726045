@charset "UTF-8";

@font-face {
  font-family: "vecoplan";
  src:url("../fonts/vecoplan.eot");
  src:url("../fonts/vecoplan.eot?#iefix") format("embedded-opentype"),
    url("../fonts/vecoplan.woff") format("woff"),
    url("../fonts/vecoplan.ttf") format("truetype"),
    url("../fonts/vecoplan.svg#vecoplan") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "vecoplan" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "vecoplan" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
    position: relative;
    top: 1px;
    .page-header-content .social-media-icons & {
        color: $c-blue;
    }
    .page-header-content .contact & {
        color: $c-ltblue;
            
    } 
    
}

.icon-search {
    top: 2px;
}

.icon-angle-down:before {
  content: "\61";
}
.icon-angle-left:before {
  content: "\62";
}
.icon-angle-right:before {
  content: "\63";
}
.icon-angle-up:before {
  content: "\64";
}
.icon-facebook:before {
  content: "\65";
}
.icon-twitter:before {
  content: "\66";
}
.icon-google-plus:before {
  content: "\67";
}
.icon-phone:before {
  content: "\68";
}
.icon-envelope-o:before {
  content: "\69";
}
.icon-tumblr:before {
  content: "\6a";
}
.icon-linkedin:before {
  content: "\6b";
}
.icon-youtube-play:before {
  content: "\6c";
}
.icon-comments:before {
  content: "\6d";
}
.icon-commenting:before {
  content: "\6e";
}
.icon-chevron-up:before {
  content: "\6f";
}
.icon-chevron-right:before {
  content: "\70";
}
.icon-chevron-left:before {
  content: "\71";
}
.icon-chevron-down:before {
  content: "\72";
}
.icon-chevron-circle-up:before {
  content: "\73";
}
.icon-chevron-circle-right:before {
  content: "\74";
}
.icon-chevron-circle-left:before {
  content: "\75";
}
.icon-chevron-circle-down:before {
  content: "\76";
}
.icon-search:before {
  content: "\77";
}
.icon-bars:before {
  content: "\78";
  color: $c-text-white;
}
.icon-arrow-down:before {
  content: "\79";
}
.icon-arrow-left:before {
  content: "\7a";
}
.icon-arrow-right:before {
  content: "\41";
}
.icon-arrow-up:before {
  content: "\42";
}

