.showroom-index-index{
  .col1-layout .std .breadcrumbs li{
    font-size: 12px;
  }
  h1{
    padding-bottom: 20px;
  }
  h2{
    margin-left: 15px;
    padding-top: 25px;
  }
  label[for=filter]{
    margin: 15px 0;
  }
  .thumbnail{
    margin-bottom: 5px;
    img.img-responsive{
      width: 130px;
      height: 96px;
    }
  }
  .container-videoroom div.thumb{
    padding-bottom: 15px;
  }
}