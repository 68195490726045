%button {
    padding: .5em 2em;
    color: #fff;
    background-color: $c-blue;
    border: none;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    &:hover {
        background-color: darken($c-blue, 10%);
    }
    &:active {
        transform: translate(1px, 1px);
    }
    &:active,
    &:focus {
        outline: none;
    }
}