.sample {
    display: inline-block;
    float: left;
    a {
        border: none;
        float: left;
    }
    .thumbnail {
        display: inline-block;
        padding: 0;
        margin-bottom: 0;
    }
}

.sample-block {
    overflow: auto;
    @include clearfix();
}